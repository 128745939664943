import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseUrl = `${process.env.REACT_APP_BASE_URL}/user/`

export const userApiService = createApi({
    reducerPath: 'userApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().authSlice.token
            headers.set('Authorization', `Bearer ${token}`)
            return headers
        }
    }),
    endpoints: (builder) => ({
        createUser: builder.mutation({
            query: (payload) => ({
                url: 'create',
                method: 'POST',
                body: payload
            })
        }),
        updateUser: builder.mutation({
            query: (payload) => ({
                url: 'update',
                method: 'PUT',
                body: payload
            })
        }),
        handleWishlist: builder.mutation({
            query: (id) => ({
                url: `handle-wishlist/${id}`,
                method: 'PUT'
            })
        }),
        getInstructors: builder.query({
            query: (data) => {
                return ({
                    url: data?.id ? `instructors/${data?.id}` : `instructors`,
                    method: "GET",
                    params: data?.id ? {} : data
                })
            },
            keepUnusedDataFor: 0
        }),
        getWishlist: builder.query({
            query: () => `wishlist`,
            keepUnusedDataFor: 0
        }),
        verifyCourseCreation: builder.query({
            query: () => ({
                url: 'verify-course-creation',
                method: 'GET'
            }),
            keepUnusedDataFor: 0
        }),
    })
})

export const {
    useCreateUserMutation,
    useUpdateUserMutation,
    useHandleWishlistMutation,
    useGetInstructorsQuery,
    useGetWishlistQuery,
    useVerifyCourseCreationQuery
} = userApiService