import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { queryParams } from '../../../utils/helper'

const baseUrl = `${process.env.REACT_APP_BASE_URL}/course/`

export const courseApiService = createApi({
    reducerPath: 'courseApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().authSlice.token
            headers.set('Authorization', `Bearer ${token}`)
            return headers
        }
    }),
    endpoints: (builder) => ({
        createCourse: builder.mutation({
            query: (payload) => ({
                url: 'create',
                method: 'POST',
                body: payload
            })
        }),
        updateCourse: builder.mutation({
            query: (payload) => ({
                url: `update/${payload._id}`,
                method: 'PUT',
                body: payload
            })
        }),
        buyCourse: builder.mutation({
            query: (payload) => ({
                url: `buy`,
                method: 'POST',
                body: payload
            })
        }),
        getCourse: builder.query({
            query: (data) => ({
                url: data?.id ? `get/${data?.id}` : `get`,
                method: "GET",
                params: data?.id ? {} : data
            }),
            keepUnusedDataFor: 0
        }),
        getCoursesByCategory: builder.query({
            query: (data) => {
                let query = queryParams(data)
                return `get-by-category${query}`
            },
            keepUnusedDataFor: 0
        }),
        getMyList: builder.query({
            query: () => `get-my-list`,
            keepUnusedDataFor: 0
        })
    })
})

export const {
    useCreateCourseMutation,
    useUpdateCourseMutation,
    useBuyCourseMutation,
    useGetCoursesByCategoryQuery,
    useGetCourseQuery,
    useGetMyListQuery
} = courseApiService