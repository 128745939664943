import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { toast } from 'react-toastify'

export const validateVideoDuration = (file, max, min = 0, message) => {

    const video = document.createElement('video')
    video.preload = 'metadata'

    const onLoadedMetaDataPromise = new Promise((resolve) => {
        video.onloadedmetadata = () => {
            window.URL.revokeObjectURL(video.src)
            resolve(video.duration)
            video.remove()
        }
    })

    video.src = window.URL.createObjectURL(file)

    return onLoadedMetaDataPromise.then(res => {

        if (res <= max && res >= min) {
            return true
        } else {
            toast.error(message ? message : `Video must be a length of ${max} seconds or less`)
            return false
        }

    }).catch(e => {
        return false
    })

}

export const queryParams = (data) => {

    if (data) {
        let queryStrings = Object.entries(data).map(([key, value]) => {
            if (value) {
                return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
            }
            return ''
        })

        queryStrings = queryStrings.filter(item => item !== '')
        queryStrings = queryStrings?.length ? queryStrings.join("&") : ""

        return `?${queryStrings}`
    }

    return ``

}

export const getQueryParams = (search) => {

    const params = new URLSearchParams(search);
    const queryParams = {};

    for (let [key, value] of params.entries()) {
        queryParams[key] = value;
    }

    return queryParams;

}

export const calculateCartAmount = cart => {

    let total_amount = 0

    if (cart && cart?.length > 0) {
        total_amount = cart.reduce((prev, next) => prev + next?.price?.amount, 0)
    }

    return {
        total_amount
    }

}

export const getVideoDuration = (videoUrl) => {

    return new Promise((resolve, reject) => {
        const video = document.createElement('video');

        video.onloadedmetadata = () => {
            const duration = video.duration;
            URL.revokeObjectURL(video.src);
            resolve(duration);
        };

        video.onerror = (error) => {
            reject(new Error('Failed to load video metadata: ' + error.message));
        };

        video.crossOrigin = 'anonymous';
        video.src = videoUrl;
    });
}

export const getMultipleVideoDurations = (videoUrls) => {
    const promises = videoUrls.map(url => getVideoDuration(url));
    return Promise.all(promises);
}

export const getTotalVideoDurationString = (videoUrls) => {

    let urls = videoUrls?.filter(item => item !== "")

    if (urls?.length > 0) {
        return getMultipleVideoDurations(urls)
            .then(durations => {
                const totalDuration = durations.reduce((total, duration) => total + duration, 0);
                const totalHours = Math.floor(totalDuration / 3600);
                const totalMinutes = Math.floor((totalDuration % 3600) / 60);
                const totalSeconds = Math.floor(totalDuration % 60);

                const formatted = [];
                if (totalHours > 0) {
                    formatted.push(`${totalHours} hours`);
                }
                if (totalMinutes > 0 || totalHours > 0) {
                    formatted.push(`${totalMinutes} minutes`);
                }
                formatted.push(`${totalSeconds} seconds`);

                return formatted.join(' and ');
            })
            .catch(error => {
                console.error('Error:', error);
                throw error;
            });
    }

    return "00:00"

}

export const getTime = date => {
    dayjs.extend(relativeTime)
    return dayjs(date).fromNow()
}

export const formatDate = (date, time) => {
    const dayjsdate = dayjs(date);
    let formattedDate = dayjsdate.format('DD MMMM, YYYY');

    if (time) {
        formattedDate = dayjsdate.format('DD MMMM, YYYY HH:mm');
    }

    return formattedDate
}